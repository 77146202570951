export default [
  {
    code: 102101040000,
    size: "180x80x225",
    color: "ecobagProperties.color.white",
    handle: "ecobagProperties.handles.twisted",
    gsm: 100,
    boxSize: 250,
  },
  {
    code: 104101040000,
    size: "240x100x320",
    color: "ecobagProperties.color.white",
    handle: "ecobagProperties.handles.twisted",
    gsm: 100,
    boxSize: 250,
  },
  {
    code: 106101040000,
    size: "240x100x360",
    color: "ecobagProperties.color.white",
    handle: "ecobagProperties.handles.twisted",
    gsm: 100,
    boxSize: 250,
  },
  {
    code: 108101040000,
    size: "305x170x340",
    color: "ecobagProperties.color.white",
    handle: "ecobagProperties.handles.twisted",
    gsm: 100,
    boxSize: 100,
  },
  {
    code: 114101040000,
    size: "500x180x390",
    color: "ecobagProperties.color.white",
    handle: "ecobagProperties.handles.twisted",
    gsm: 100,
    boxSize: 100,
  },
]
